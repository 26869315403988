.wrapper {
	background: #040200;
	padding: 100px 0 0;
	@media (max-width: 1440px) {
		padding: 60px 0 0;
	}
	@media (max-width: 800px) {
		padding: 70px 0 0;
	}
}
.title {
	//font-size: 82px;
	font-size: 70px;
	text-transform: uppercase;
	margin-bottom: 50px;
	max-width: 1150px;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1440px) {
		max-width: 750px;
		font-size: 46px;
	}
	@media (max-width: 800px) {
		font-size: 35px;
		font-weight: 400;
		max-width: 650px;
		margin-bottom: 20px;
	}
}
.presidentWrapper {
	display: flex;
	position: relative;
	justify-content: space-between;
}
.partnersItemVideo {
	max-width: 500px;
	width: 100%;
}
.presidentWrapperImage {
	position: absolute;
	bottom: 0;
	right: 0;
	& > img {
		width: 500px;
	}
	@media (max-width: 1440px) {
		& > img {
			width: 350px;
		}
		position: relative;
		margin-top: -60px;
	}

	@media (max-width: 1080px) {
		display: none;
	}
}
.presidentWrapperLink {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 501px;
	height: 85px;
	border-radius: 12px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	& > a {
		color: #040200;
		font-size: 24px;
		line-height: normal;
		font-family: "Montserrat", sans-serif;
	}
	@media (max-width: 1440px) {
		width: 351px;
		height: 65px;
		& > a {
			font-size: 20px;
		}
	}
}
.presidentVideoWrapper {
	max-width: 890px;
	width: 100%;
	position: relative;
	margin-bottom: -16px;
	& > img {
		position: absolute;
		bottom: -14px;
		right: 0;
		z-index: 3;
	}
	@media (max-width: 1440px) {
		max-width: 850px;
		width: 100%;
		margin-right: 40px;
	}
	& > iframe {
		width: 100%;
		height: 100%;
	}
	@media (max-width: 1080px) {
		max-width: 700px;
		margin: 0 auto;
	}
}

.playBtnMask {
	width: 50px;
	height: 50px;
	background: #000;
}
.playBtn {
	
}