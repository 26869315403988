.wrapper {
	background: #040200;
	z-index: 3;
	padding: 0px 0 100px;
}
.title {
	font-size: 72px;
	line-height: normal;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 50px;
	text-align: center;
	@media (max-width: 1000px) {
		font-size: 54px;
		margin-bottom: 30px;
	}
	@media (max-width: 720px) {
		font-size: 41px;
		font-weight: 400;
	}
	@media (max-width: 450px) {
		font-size: 35px;
		max-width: 300px;
		margin: 0 auto 30px;
	}
}
.productsWrapper {
	display: flex;
	justify-content: space-between;
}
.productsItem {
	outline: none;
	width: 500px !important;
	height: 730px;
	display: flex !important;
	flex-direction: column !important;
	color: #fff;
	& > img {
		width: 500px;
		border-radius: 12px;
		margin-bottom: 30px;
	}
	@media (max-width: 600px) {
		margin: 0 auto;
		width: 350px !important;
		height: fit-content;
		& > img {
			width: 350px;
			margin-bottom: 10px;
		}
		& > a.main-button {
			font-size: 12px !important;
			font-weight: 600;
			padding: 17px 55px !important;
		}
	}
}
.productsBtn {
	@media (max-width: 600px) {
		margin-top: 10px;
		font-size: 12px !important;
		font-weight: 600;
		padding: 17px 55px !important;
	}
}

.productsItemName {
	font-family: "Montserrat", sans-serif;
	font-size: 22px;
	font-weight: 700;
	line-height: normal;
	max-width: 370px;
	margin-bottom: 10px;
	@media (max-width: 600px) {
		font-size: 17px;
	}
}

.productsItemDesc {
	font-size: 16px;
	line-height: 130.4%;
	font-family: "Montserrat", sans-serif;
	@media (max-width: 600px) {
		font-size: 14px;
	}
}
.productsItemButton {
	margin-top: auto;
}
.slickArrowLeft {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	left: -48px;
	top: 240px;
	background: #DFA440;
	&:hover {
		transition: 0.2s;
		background: #D69A36;
	}


	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > svg {
			width: 30px !important;
			height: 20px !important;
		}
		top: auto;
		left: 38%;
		bottom: 0px;
	}
	@media (max-width: 520px) {
		left: 33%;
	}
}
.slickArrowRight {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	right: -28px;
	top: 240px;
	background: #DFA440;
	transition: 0.2s;
	&:hover {
		background: #D69A36;
		transition: 0.2s;
	}
	@media (max-width: 1660px) {
		right: -28px;
	}
	@media (max-width: 1140px) {
		right:-24px
	}
	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > svg {
			width: 30px !important;
			height: 20px !important;
		}
		top: auto;
		right: 38%;
		bottom: 0px;
	}
	@media (max-width: 520px) {
		right: 33%;
	}
}
