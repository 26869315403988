.wrapper {
	background: #040200;
	padding-bottom: 100px;
}
.innerWrapper {
	display: flex;
	gap: 20px;
	@media (max-width: 1060px) {
		flex-direction: column;
	}
}
.title {
	max-width: 630px;
	font-size: 72px;
	text-transform: uppercase;
	margin-bottom: 50px;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1200px) {
		font-size: 54px;
		margin-bottom: 30px;
	}
	@media (max-width: 1060px) {
		max-width: 100%;
		font-size: 46px;
	}
	@media (max-width: 800px) {
		max-width: 350px;
		text-align: center;
		margin: 0 auto 30px;
	}
	@media (max-width: 500px) {
		font-size: 35px ;
	}
}
.leftBlock {
	padding-right: 10px;
}
.leftBlockItem {
	margin-bottom: 35px;
	width: 463px;
	position: relative;
	height: 195px;
	margin-left: 37px;
	border-radius: 12px;
	background: #1b1b1b;
	display: flex;
	justify-content: center;
	flex-direction: column;
	// align-items: center;
	padding-left: 70px;
	& > p {
		font-family: Montserrat;
		font-size: 18px;
		max-width: 350px;
	}
	&:last-child > p {
		margin-top: -8px;
	}
	@media (max-width: 1200px) {
		height: 160px;
		padding-left: 55px;
	}
	@media (max-width: 1060px) {
		width: 95%;
		& > p {
			max-width: 100%;
		}
	}
	@media (max-width: 700px) {
		width: 93%;
		height: 132px;
		& > p {
			font-size: 14px;
		}
	}
	@media (max-width: 500px) {
		width: 90%;
	}
}
.leftBlockItemBold {
	font-size: 64px;
	margin-bottom: 15px;
	@media (max-width: 700px) {
		margin-bottom: 10px;
		font-size: 35px;
		font-weight: 400;
	}
}
.leftBlockItemNumber {
	font-size: 45px;
	position: absolute;
	left: -37px;
	top: 60px;
	border-radius: 15px;
	background: #dfa440;
	width: 73px;
	height: 73px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	@media (max-width: 700px) {
		width: 49px;
		height: 49px;
		top: 41px;
		left: -25px;
		font-size: 25px;
	}
}
.rightBlock {
	max-width: 890px;
	margin-right: 10px;
	& > div > img {
		border-radius: 12px;
	}
	@media (max-width: 1060px) {
		margin: 0 auto;
	}
}
.galleryFirstLine {
	display: flex;
	gap: 24px;
	margin-bottom: 21px;
	& > img:nth-child(1) {
		width: 31.57%;
	}
	& > img:nth-child(2) {
		width: 65.85%;
	}
	@media (max-width: 1200px) {
		gap: 12px;
		margin-bottom: 12px;
	}
}
.gallerySecondLine {
	display: flex;
	gap: 24px;
	& > img {
		width: 48.65%;
	}
	@media (max-width: 1200px) {
		gap: 12px;
	}
}
