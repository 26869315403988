.wrapper {
	background: #040200;
	padding: 100px 0 0px;
	
}
.title {
	font-size: 76px;
	max-width: 1200px;
	text-transform: uppercase;
	text-align: center;
	margin: 0 auto 50px;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1200px) {
		font-size: 62px;
		margin-bottom: 30px;
	}
	@media (max-width: 1000px) {
		font-size: 52px;
		margin-bottom: 30px;
	}
	@media (max-width: 800px) {
		font-size: 44px;
		margin-bottom: 30px;
	}
	@media (max-width: 720px) {
		font-size: 32px;
		font-weight: 400;
	}
	@media (max-width: 450px) {
		font-size: 25px;
		max-width: 300px;
		margin: 0 auto 30px;
	}
}
.partnersWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(1, 640px);
	grid-gap: 20px;
	@media(max-width: 1660px) {
		display: none;
	}
}
.partnersItem {
	display: flex !important;
	flex-direction: column !important;
	height: 640px;
	outline: none;
	& > img {
		cursor: pointer;
		width: 500px;
		margin-bottom: 30px;
		border-radius: 12px;
	}
	@media (max-width: 600px) {
		margin: 0 auto;
		width: 350px !important;
		height: fit-content;
		& > img {
			width: 350px;
			margin-bottom: 10px;
		}
	}
}
.partnersItemName {
	font-size: 50px;
	line-height: 150%;
	margin-bottom: 10px;
	@media (max-width: 600px){
		font-size: 35px;
	}
}
.partnersItemText {
	font-family: Montserrat;
	font-size: 18px;
	max-width: 350px;
	& > span {
		font-family: Montserrat;
		font-size: 18px;
		font-weight: 700;
	}
}
.partnersItemButton {
	margin-top: auto;
	@media(max-width: 600px) {
		margin: 15px 0 0;
		width: 250px;
		& > a {
			font-size: 12px !important;
			font-weight: 600;
			padding: 21px 52px !important;
			font-size: 15px;
		}
	}
}
.partnersWrapperSlider {
	display: none;
	@media(max-width: 1660px) {
		display: block;
	}
}


.slickArrowLeft {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	left: -48px;
	top: 200px;
	background: #DFA440;
	&:hover {
		transition: 0.2s;
		background: #D69A36;
	}


	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > svg {
			width: 30px !important;
			height: 20px !important;
		}
		top: auto;
		left: 38%;
		bottom: 0px;
	}
	@media (max-width: 600px) {
		left: 32%;
	}

}
.slickArrowRight {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	right: -48px;
	top: 200px;
	background: #DFA440;
	transition: 0.2s;
	&:hover {
		background: #D69A36;
		transition: 0.2s;
	}
	@media (max-width: 1660px) {
		right:-28px
	}
	@media (max-width: 1140px) {
		right:-24px
	}
	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > svg {
			width: 30px !important;
			height: 20px !important;
		}
		top: auto;
		right: 38%;
		bottom: 0px;
	}
	@media (max-width: 600px) {
		right: 32%;
	}
}

.disabledArrow {
	cursor: auto;
	background: #d8c29e;
	&:hover {
		background: #d8c29e;
	}
	& > svg {
		cursor: auto !important;
	}

}
.partnersItemVideoModal {
	position: fixed;
	top: 0;
	left: 0;
	right:0;
	bottom: 0;
	backdrop-filter: blur(2px);
	overflow: auto;
	padding: 40px;
	background: rgba(0, 0, 0, .5);
	z-index: 9999;
}
.partnersItemVideoModal > div {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.partnersItemVideoModal .partnersItemVideo {
	width: 100%;
	margin-bottom: 50px;
}