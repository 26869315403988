.wrapper {
	background: #040200;
	padding: 0px 0 70px;
}
.title {
	font-size: 72px;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
	margin-bottom: 50px;
	text-align: center;
	@media (max-width: 1300px) {
		font-size: 60px;
	}
	@media (max-width: 1100px) {
		font-size: 55px;
		max-width: 600px;
		margin: 0 auto 30px;
	}
	@media (max-width: 700px) {
		font-size: 35px;
		font-weight: 400;
		max-width: 350px;
	}
}
.questionsWrapper {
	max-width: 1280px;
	margin: 0 auto;
}
.questionsItem {
	margin-bottom: 35px;
}
.questionsItemQuestion {
	cursor: pointer;
	padding: 30px;
	border-radius: 12px;
	background: #181818;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	font-family: Montserrat;
	& > p {
		font-size: 22px;

		line-height: 108%;
		font-weight: 700;
	}
	@media (max-width: 650px) {
		padding: 25px;
		& > p {
			font-size: 18px;
		}
	}
}
.questionsItemAnswear {
	padding: 0 60px;
	font-size: 16px;
	line-height: 150%;
	font-family: Montserrat;
	@media (max-width: 650px) {
		padding: 0 30px;
		font-size: 14px;
	}
}
.questionsItemQuestionOpen {
	transform: rotate(45deg);
	transition: 0.2s;
}
