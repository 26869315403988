.wrapper {
	background: #040200;
	padding: 0 0 100px;
}
.title {
	font-size: 72px;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
	text-align: center;
	margin: 0 auto 50px;
	@media (max-width: 1400px) {
		font-size: 62px;
		margin-bottom: 30px;
	}
	@media (max-width: 1010px) {
		font-size: 45px;
		font-weight: 400;
		max-width: 600px;
		margin: 0 auto 30px;
	}
	@media (max-width: 700px) {
		font-size: 35px;
	}
}
.blogersWrapper {
	display: grid;
	grid-template-columns: repeat(4, minmax(auto, 1fr));
	grid-gap: 20px;
	& > div {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		background: #ffffff;
		max-height: 280px;
		object-fit: contain
	}
	& > div > img {
		width: 100%
	 }
	@media(max-width: 1600px) {
		//display: none;
		//grid-template-columns: repeat(3, 1fr);

	}
	@media(max-width: 1200px) {
		//display: none;
		grid-template-columns: repeat(3, 1fr);
		grid-gap:10px ;
	}
	@media(max-width: 850px) {
		//display: none;
		grid-template-columns: repeat(2, 1fr);
	}

}
.blogersWrapperSlider {
	display: none;
	@media(max-width: 1600px) {
		display: block;

	}
}

.slickArrowLeft {
	display: block;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	left: -48px;
	top: 200px;

	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > div > img {
			width: 52px;
			height: 52px;
		}
		top: auto;
		left: 38%;
		bottom: 70px;
	}
	@media (max-width: 520px) {
		left: 33%;
	}
}
.slickArrowRight {
	display: block;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	right: -48px;
	top: 200px;
	@media (max-width: 1140px) {
		right:-24px
	}
	@media (max-width: 700px) {
		width: 52px;
		height: 52px;
		& > div > img {
			width: 52px;
			height: 52px;
		}
		top: auto;
		right: 38%;
		bottom: 70px;
	}
	@media (max-width: 520px) {
		right: 33%;
	}
}
