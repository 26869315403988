.wrapper {
	background: #dfa440;
	padding: 50px 0 50px;
}
.title {
	font-size: 80px;
	text-transform: uppercase;
	margin-bottom: 50px;
	text-align: center;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1300px) {
		font-size: 62px;
	}
	@media (max-width: 1100px) {
		font-size: 50px;
		max-width: 600px;
		margin: 0 auto 30px;
	}
	@media (max-width: 1100px) {
		font-size: 35px;
		font-weight: 400;
		max-width: 400px;
	}
}
.communityWrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 50px 150px;
	@media (max-width: 1300px) {
		grid-gap: 25px 75px;
	}
	@media (max-width: 1100px) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.communityItem {
	border-radius: 12px;
	background: #1b1b1b;
	height: 206px;
	padding-right: 34px;
	display: flex;
	align-items: center;
	@media (max-width: 600px) {
		height: 114px;
		& > img {
			height: 114px;
		}
	}
}
.communityItemImage {
	border-radius: 12px;
	margin-right: 20px;
}
.communityItemContent {
	font-family: Montserrat;
	font-size: 22px;
	& > span {
		font-weight: 700;
		font-family: Montserrat;
		font-size: 22px;
	}
	@media (max-width: 600px) {
		font-size: 12px;
		max-width: 300px;
		& > span {
			font-size: 12px;
		}
	}
}
